/* Layout principal */
.app-container {
  display: grid;
  grid-template-columns: 3fr 1fr; /* Coluna principal e coluna de publicidade */
  gap: 20px;
  margin: 20px;
}

.main-column {
  display: flex;
  flex-direction: column;
}

/* Categorias e Pesquisa */
.filter-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.categories button {
  padding: 5px 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  border-radius: 3px;
  cursor: pointer;
}

.categories button.selected {
  background-color: #007bff;
  color: white;
}

.search-container {
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Lista de Grupos */
.group-list {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Duas colunas */
  gap: 20px;
}

.group-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.group-item img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.group-item h3 {
  margin: 10px 0;
}

.group-item p {
  font-size: 14px;
  color: #555;
}

/* Controles de Paginação */
.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.pagination-controls button {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

/* Coluna de Publicidade */
.ad-column {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.ad-content {
  margin-top: 10px;
}

.adsense-container {
  margin: 20px 0;
  text-align: center;
}

.adsense-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

/* Estilo para os campos de formulário */
.add-group-form {
  margin-bottom: 20px;
}

.add-group-form input,
.add-group-form textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Estilo para o container de telefone e código do país */
.phone-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.phone-input {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.country-code-select {
  padding: 5px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 130px; /* Tamanho ajustado para exibir bandeira e código */
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.pagination-top {
  margin-bottom: 10px;
}

.pagination-bottom {
  margin-top: 10px;
}

.page-select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 10px;
}

.total-groups {
  font-size: 14px;
  color: #555;
}
.group-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.group-item img {
  width: 100px; /* Ajuste conforme necessário */
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto; /* Centraliza horizontalmente */
  display: block;
}

.no-image-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: #888;
  font-size: 12px;
}

.keywords-container {
  margin-top: 10px;
}

.keyword-label {
  display: inline-block;
  padding: 5px 10px;
  margin: 2px;
  font-size: 10px;
  color: white;
  background-color: #007bff;
  border-radius: 12px;
  cursor: pointer;
  text-transform: capitalize;
  transition: background-color 0.3s ease;
}

.keyword-label:hover {
  background-color: #0056b3;
}

.loading-indicator {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 20px 0;
}

/* App.css Adjustments */
@media only screen and (max-width: 600px) {
  .content-wrapper {
    flex-direction: column;
    padding: 10px;
  }
  .group-list {
    grid-template-columns: 1fr;
  }
  .ad-column {
    margin-top: 20px;
  }
}